import React from 'react'
import HeaderWiki from './HeaderWiki'

const Connect = props => {
	return (
		<div id='internal' className='wiki'>
			<div className='wrapper'>
				<h1 className='internal-title'>
					Essence Wiki
				</h1>
				<div className='box-wrapper'>
					<HeaderWiki />

					<div className='box-content wiki-content'>
						<h2 styles={{ maxWidth: 350 }} className='wiki-title trn' data-trn-key='label_howtoplay'>Download and installing the game</h2>
						<hr className='small invisible' />
						<h3 className='wiki-subtitle'>EN & RU Game Client</h3>
						<div className='download-options'>
							<a href='https://drive.google.com/file/d/1aRcDL3Yy0G7YbVBaYpT8fQWXj2S230I4/view?usp=sharing'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36px' height='36px'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z'/></svg>
								<h3 className='trn' data-trn-key='label_clientdownload'>
									Client Download
								</h3>
								<small className='trn' data-trn-key='Option 1 - GoogleDisk'>Option 1 - GoogleDisk</small>
							</a>
							<a href='https://drive.google.com/file/d/1pcFzdxYEtIPy0zO6fPku6ED3kEqlFAP8/view?usp=drive_link'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36px' height='36px'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z'/></svg>
								<h3 className='trn' data-trn-key='label_clientdownload'>
									Client Download
								</h3>
								<small className='trn' data-trn-key='Option 2 - GoogleDisk'>Option 2 - GoogleDisk 2</small>
							</a>
							<a href='https://disk.yandex.ru/d/qOdE8GTBHoIMVQ'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36px' height='36px'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z'/></svg>
								<h3 className='trn' data-trn-key='label_clientdownload'>
									Client Download
								</h3>
								<small className='trn' data-trn-key='label_download_option2'>Option 3 - Yandex</small>
							</a>
							<a href='https://fex.net/ru/s/bbm2at4'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36px' height='36px'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z'/></svg>
								<h3 className='trn' data-trn-key='label_clientdownload'>
									Client Download
								</h3>
								<small className='trn' data-trn-key='label_download_option3'>Option 4 - Torrent</small>
							</a>
							
						</div>
						<hr className='small invisible' />
						<h3 className='wiki-subtitle'></h3>
						<div className='download-options'>
							<a href='https://mega.nz/file/irp2AJaD#c5HX9m0ACnwbwotKBQd9vSxhHIdCe5Z1KYGw350qoIA'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36px' height='36px'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z'/></svg>
								<h3 className='trn' data-trn-key='label_clientdownload'>
									Client Download
								</h3>
								<small className='trn' data-trn-key='Option 4 - Mega'>Option 5 - Mega</small>
							</a>
							
						</div>
						<hr className='small invisible' />
						<h3 className='wiki-subtitle'> Game Patch Server‎ </h3>
						<div className='download-options'>
							<a href='https://drive.google.com/file/d/1b9SED15VNLUZ1UKfAbgsOvDo560Iz4eQ/view?usp=sharing'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36px' height='36px'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z'/></svg>
								<h3>
									EN Game Patch
								</h3>
								<small className='trn' data-trn-key='label_download_option1'>Option 1 - GoogleDisk</small>
							</a>
							<a href='https://drive.google.com/file/d/1bF0GWrzpw_RF2nyZnQE2YFDDG9i4Of9m/view?usp=sharing'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36px' height='36px'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z'/></svg>
								<h3>
									RU Game Patch
								</h3>
								<small className='trn' data-trn-key='label_download_option1'>Option 2 - GoogleDisk</small>
							</a>
							<a href='https://fex.net/ru/s/krrvlf2'>
								<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='36px' height='36px'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M13 5v6h1.17L12 13.17 9.83 11H11V5h2m2-2H9v6H5l7 7 7-7h-4V3zm4 15H5v2h14v-2z'/></svg>
								<h3>
									Game Update
								</h3>
								<small className='trn' data-trn-key='label_download_option1'>Option 3 - Fex</small>
							</a>
						</div>												
						<div className='trn' data-trn-key='label_howtoplay_04'>
							<p><h3 class='wiki-subtitle'>Installing Full Game </h3></p><ul><li>Where you download client RU or English.</li> <li>Then Extract all files.</li> <li>Download game patch, extract files from patch to game folder.</li> </ul>
						</div>
						<hr />
						<div className='trn' data-trn-key='label_howtoplay_05'>
							<p><h3 class='wiki-subtitle'>Starting the Game</h3></p><ul><li>Then enter "l2abyss.com" folder and run L2.exe.</li> </ul>
						</div>
						<hr />
						<div className='trn' data-trn-key='label_howtoplay_06'>
							<p><h3 class='wiki-subtitle'>Creating Master account and Game account</h3></p><ul><li>Register on the site Master Account</li> <li>Enter Master Account in website</li> <li>Cr﻿eate a Game Account inside the Master Ac﻿count</li> </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Connect